import { AbstractControl } from '@angular/forms';
export class DateValidation {

    static greatThanToday(control: AbstractControl) {
        if (control.value) {
            const date = new Date(control.value);
            const dateNow = new Date();

            if (date <= dateNow) {
                return { dateGreatThanToday: true };
            }
        }

        return null;
    }

    static monthYearValidator(control: AbstractControl) {
        console.log(control.value);
        if (control.value) {
            let valid = control.value.replace(/\s/g, '').match(/^\d{2}\/\d{4}$/g);
            console.log('Valido => ', valid);
            if (!valid) {
                return { monthYearValidator: true };
            }
        }

        
        // if (AC && AC.value && !new Date() (AC.value, 'MM/YYYY', true).isValid()) {
        //   return { dateVaidator: true };
        // }
        return null;
      }
}
