import { StatusAnuncio } from "../../Base/Enums/status-anuncio.enum";
import { TipoAnuncioEnum } from "../../Base/Enums/tipo-anuncio.enum";
import { CategoriaServicoEnum } from "../../Servicos/Enums/categoria-servico.enum";
import { PropostaModel } from "../proposta.model";
import { ImagemModel } from "src/app/Shared/Models/Base/imagem.model";

export class AnuncioVmModel {
  id: string;
  clienteId: string;
  clienteContato: string;
  clienteNome: string;
  rua: string;
  estadoId: number;
  estado: string;
  cidadeId: number;
  cidade: string;
  bairroId: number;
  bairro: string;
  zonaId: number;
  zona: string;
  complemento: string;
  servicoId: number;
  nomeServico: string;
  categoriaServico: CategoriaServicoEnum;
  nomeContratante: string;
  idadeContratante: number;
  dataEvento: string | null;
  horarioEvento: string | null;
  temaEvento: string;
  numeroConvidados: number;
  descricaoEvento: string;
  precoAbaixoDe: number;
  status: StatusAnuncio;
  tempoBid: number | null;
  numeroBid: string;
  primeiraImagem: any;
  segundaImagem: any;
  terceiraImagem: any;
  quartaImagem: any;
  primeiraImagemDescricao: string;
  segundaImagemDescricao: string;
  terceiraImagemDescricao: string;
  quartaImagemDescricao: string;
  primeiraImagemNome: any;
  segundaImagemNome: any;
  terceiraImagemNome: any;
  propostas: Array<PropostaModel> = [];
  propostasSelecionadas: Array<PropostaModel> = [];
  propostasCanceladas: Array<PropostaModel> = [];
  tipoAnuncio: TipoAnuncioEnum;
  imagens: Array<ImagemModel> = [];

  constructor(init?: Partial<AnuncioVmModel>) {
    Object.assign(this, init);
  }
}
